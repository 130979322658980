import React from 'react';
import Section from '../../Section'
import { FaGithubSquare,FaLinkedin, FaEnvelope } from 'react-icons/fa'
import {Card} from 'reactstrap'

const contact = (props) => {

    return(
        <Section id={"contact"}>
            <h3>Contact</h3>
            <Card body>
            <p>Reach out to me if you want to collaborate!</p>
                <div>
                    <div className={"contact-items"} >
                        <p>
                            <a href={"mailto:jdaosavanh@gmail.com"}>
                                <FaEnvelope/>
                                <span>jdaosavanh@gmail.com</span>
                            </a>

                        </p>
                        <p>
                            <a href={"https://github.com/jdaosavanh"}>
                                <FaGithubSquare/>
                                <span>Github</span>
                            </a>
                        </p>
                        <p>
                            <a href={"https://www.linkedin.com/in/jameson-daosavanh-6b43b667/"}>
                                <FaLinkedin/>
                                <span>Linkedin</span>
                            </a>
                        </p>
                    </div>
                </div>
            </Card>
        </Section>
    );
};

export default contact

