import React, { Component } from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container } from 'reactstrap'
import { HashLink as Link } from 'react-router-hash-link';
import './AppNavBar.scss'

class AppNavbar extends Component{

    render(){

        return (
            <div>
                <Navbar dark expand={"lg"} className={"deep-blue-bgc text-white"}>
                    <Container>
                        <NavbarBrand href={"/"}>Jameson Daosavanh</NavbarBrand>
                        <NavbarToggler onClick={this.toggle}/>
                        <Collapse navbar>
                            <Nav className={"ml-auto"} navbar >
                                <NavItem>
                                    <Link to="/#about" href={"#contact"} className="nav-link">
                                        About
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/#contact" href={"#contact"} className="nav-link">
                                        Contact
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link to={"/projects"} className={"nav-link"} style={{cursor: "pointer"}}>
                                        Projects
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <NavLink href={"/resume/Jameson_Daosavanh_Resume.pdf"} target={"_blank"}>
                                        Resume
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}




export default  AppNavbar;

