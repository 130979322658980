import React, { Component } from 'react';
import {
    Row,
    Col,
    Container,
    Card,
    CardImg,
    CardText,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardLink,
    Button } from 'reactstrap';
import {FaLink} from 'react-icons/fa'
import './Projects.scss'

class Projects extends Component{

    render(){
        return (
            <div className={"projects-wrapper"}>
                <div className={"title"}>
                    <h2>Projects</h2>
                </div>
                <Container className={"no-padding"}>
                    <Row className={"project-title"}>
                        <h4 style={{float:"left"}}>Magento</h4>
                    </Row>
                    <Row className={"magento"}>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/glosostore.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Gloso Store</CardTitle>
                                    <CardText>
                                        Extensive site with multiple B2B integrations. Help create import/exports for sales, products, and usage.
                                    </CardText>
                                    <CardLink href={"https://www.glosostore.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/mrheater.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Mr. Heater</CardTitle>
                                    <CardText>
                                        Help create a schematic parts tool that is used to find parts.
                                        Integrated schematics tool with a pdf library for uploading documents on parts.
                                    </CardText>
                                    <CardLink href={"https://www.mrheater.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/rollrite.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>RollRite</CardTitle>
                                    <CardText>
                                        Rollrite's site that allows for quoting products.
                                        Integrated a schematic parts tool into their system.
                                    </CardText>
                                    <CardLink href={"https://www.rollrite.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/surface_material.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Surface Materials</CardTitle>
                                    <CardText>
                                        Help implement a system that allows for products to be location based.
                                    </CardText>
                                    <CardLink href={"https://www.surfacematerials.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/matchmg.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Matchmg</CardTitle>
                                    <CardText>
                                        Create a backend integration system for ordering parts. It allows there service works to order parts out in the workfield without difficulties. Allows for ACL to group and limit users.
                                    </CardText>
                                    <CardLink href={"https://matchmg.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/ebscomags.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Ebscomags</CardTitle>
                                    <CardText>
                                        Updated a subscription system for ordering magazines and other materials.
                                    </CardText>
                                    <CardLink href={"https://www.ebscomags.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/diabetic_promo.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Diabetic Promotions</CardTitle>
                                    <CardText>
                                        Simple Magento Site. Added custom modules for mailing. Completed the server set up and styles.
                                    </CardText>
                                    <CardLink href={"https://www.diabeticpromotions.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/multicraft.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Multicraft Inc</CardTitle>
                                    <CardText>
                                        Add extensions and kept site updated.
                                    </CardText>
                                    <CardLink href={"www.multicraftink.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/polisheats.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Polish Eats</CardTitle>
                                    <CardText>
                                        Simple Magento Site. Completed the server set up and styles.
                                    </CardText>
                                    <CardLink href={"http://polisheats.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={"project-title"}>
                        <h4 style={{float:"left"}}>Java</h4>
                    </Row>
                    <Row className={"java no-padding"}>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/esportsq.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Esportsq</CardTitle>
                                    <CardText>
                                        Esports Queue was an app for Twitch TV that served as platform that integrated the viewers and streamers. It allowed streamers to be more active with their viewers by being  able to sort viewers by specific criteria.
                                    </CardText>
                                    <CardLink href={"https://www.linkedin.com/company/esportsq/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={"project-title"}>
                        <h4 style={{float:"left"}}>WordPress</h4>
                    </Row>
                    <Row className={"wordpress no-padding"}>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/envalo.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Envalo</CardTitle>
                                    <CardText>
                                        Created Envalo's webiste. Setup up server on plesk. Style and added content.
                                    </CardText>
                                    <CardLink href={"https://www.envalo.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/gloso.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Gloso</CardTitle>
                                    <CardText>
                                        Help built and style Gloso's first implementation of CMS
                                    </CardText>
                                    <CardLink href={"https://gloso.com"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card>
                                <CardImg top width="100%" src="/images/projects/constant_aviation.jpg" alt="Card image cap" />
                                <CardBody>
                                    <CardTitle>Constant Aviation</CardTitle>
                                    <CardText>
                                        Help create website by using multiplte extendentions such as parallax
                                    </CardText>
                                    <CardLink href={"https://www.constantaviation.com/"} target={"_blank"}><FaLink/></CardLink>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={"project-title"}>
                        <h4 style={{float:"left"}}>Kata</h4>
                    </Row>
                    <Row className={"kata no-padding"}>
                            <Col lg={4} >
                                <Card >
                                    <CardImg top width="100%" src="/images/projects/gof_kata.gif" alt="Card image cap" />
                                    <CardBody>
                                        <CardTitle>Game Of Life Kata</CardTitle>
                                        <CardText>This is a simple game of Life implementation of the Game Of Life Kata </CardText>
                                        <CardLink href={"https://jdaosavanh.github.io/game_of_life/"} target={"_blank"}><FaLink/></CardLink>
                                    </CardBody>
                                </Card>
                            </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default  Projects;