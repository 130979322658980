import React from 'react'

const profile = (props) => {

    return(
        <div className="Profile">
            <img className={"profile-pic"} alt={"profile"} src="/images/profile1.png"/>
            <h2>Hi, I'm <span className={"text-redish"}>Jameson Daosavanh</span></h2>
            <h2>I'm a software engineer</h2>
        </div>
    );
};

export default profile
