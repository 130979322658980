import React from 'react';
import Section from '../../Section'
import {Card,CardText} from 'reactstrap'

const about = (props) => {

    return(
        <Section id={"about"}>
            <h3>About</h3>
            <Card body>
                <CardText>
                    I am a software engineer currently living in Columbus, Ohio. I work in DevOps on a wide range of projects. I have a passion for building and helping people, being a software engineer enables me to create solutions and fix problems.
                </CardText>
                <CardText>
                    I have been developing professionally for over 6 years, with projects ranging from large to small using agile and lean methodologies. During my day to day, I am focused on developing applications using AWS with Node, Java, and Python. In my free time, I am working on creating containerized applications with Docker and Kubernetes.
                </CardText>
                <CardText>
                    Outside of work I enjoy finding new local eats, playing video games/board games, reading, and exploring nature.
                </CardText>
            </Card>
        </Section>
    );
};

export default about