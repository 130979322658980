import React from 'react';
import Section from '../../Section'
import {Card,Table} from 'reactstrap'

const technology = (props) => {

    return(
        <Section id={"technology"}>

            <h3>Technical Experience</h3>
            <Card body>
                <Table>
                    <thead>
                    <tr>
                        <th>Technology</th>
                        <th>Years</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>AWS</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Node.js</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>JAVA/Spring</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Docker</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Golang</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>LAMP Stack</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>JavaScript/Jquery</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>SCSS/CSS/HTML</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>React</td>
                        <td>1</td>
                    </tr>
                    </tbody>
                </Table>
            </Card>

        </Section>
    );
};

export default technology
