import React, { Component } from 'react';
import {
    Row,
    Col } from 'reactstrap';
import Profile from './Profile'
import About from './Sections/About'
import Contact from './Sections/Contact'
import Technology from './Sections/Technology'
import './Allcontent.scss'

class Allcontent extends Component{

    render(){
        return (
            <div className={"allcontent-wrapper"}>
                <Row className={"max-width-100 no-margins default-row"}>
                    <Col lg={"6"} md={"5"} className={"main-left"}>
                        <Profile/>
                    </Col>

                    <Col lg={"6"} md={"7"} className={"main-right"}>
                        <About/>
                        <Technology/>
                        <Contact/>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default  Allcontent;

