import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'
import AppNavbar from './components/AppNavbar'
import Allcontent from './components/Static/Allcontent'
import Projects from './components/Static/Projects'
import { BrowserRouter as Router, Route} from 'react-router-dom'

class App extends Component {

    render() {

        return (
            <Router>
                <div className="App">
                    <AppNavbar/>
                    <Route exact path={"/"} component={Allcontent}/>
                    <Route exact path={"/projects"} component={Projects}/>
                </div>
            </Router>
        )
    }
}

export default App
